/*==========================================================================*\
  # Typography
\*==========================================================================*/
.h1, .h2, .h3, .h4, .h5, .h6 {
  letter-spacing: -2px;
}

.h1 {
  font-size: rem(70);
  line-height: rem(77);
  margin-bottom: $bl;
  font-weight: 100;
  letter-spacing: 0.6px;

  @include query-to(768) {
    font-size: rem(40);
    line-height: rem(42);
  }
}

.h2 {
  font-size: rem(49);
  line-height: rem(70);
  padding-top: rem(8);
  margin-bottom: $bl - rem(8);
  font-weight: 300;
  color: $c-black;
  letter-spacing: 0.4px;

  @include query-to(768) {
    font-size: rem(28);
    line-height: rem(42);
  }
}

.h3 {
  font-size: rem(18);
  padding-top: rem(8);
  margin-bottom: $bl - rem(8);
  letter-spacing: 0.1px;
  text-transform: uppercase;
}

.h4 {
  font-size: rem(24);
  padding-top: rem(8);
  margin-bottom: $bl - rem(8);
}

.h5 {
  font-size: rem(20);
  padding-top: rem(8);
  margin-bottom: $bl - rem(8);
}

.h6 {
  font-size: rem(16);
  padding-top: rem(8);
  margin-bottom: $bl - rem(8);
}

%lists {
  margin: 0 0 $bl $bl;

  li {
    margin-bottom: $bl/2;
  }
}

.ul {
  @extend %lists;
}

.ol {
  @extend %lists;
}

.blockquoute {

  p {
    position: relative;
    font-size: rem(28);
    line-height: rem(38);
    color: $c-black;
    text-align: center;
    padding: 0;

    &:before {
      position: absolute;
      top: -$bl;
      left: 0;
      font-size: rem(240);
      color: #eee;
      z-index: -1;
    }

  }
}

#toc_container {
  padding: $bl * 1.25 rem(20);
  margin-bottom: $bl;
  border: 1px solid #e0e0e0;
  background: #f5f5f5;
}

.toc_title {
  // @extend .h5;
  text-align: center;
  font-weight: bold;
  color: $c-black;
  // margin-bottom: $bl/2 !important;
}

.toc_list {
  margin: 0 !important;
  // font-size: rem(14);
  list-style-type: decimal;
  -webkit-column-count: 2;
          column-count: 2;
  -webkit-column-gap: rem(10);
          column-gap: rem(10);

  li {
    margin-left: $bl;
    margin-bottom: 0 !important;
  }

  a {
    color: $c-gray;

    &:hover {
      color: $c-primary;
    }
  }
}
