.img--service {
  margin: $bl/1.5 0;
}

.row--services-info {
  color: $c-black;

  .h3 {
    font-weight: bold;
  }
}

.page-template-sluzby {
  .img--hero {
    padding: $bl*3.5 0;
  }

  .btn-wrap--center {
    margin-top: $bl*3;
  }
}

.service-first {
  .section-content__main {
    margin-bottom: rem(90);
  }
}

.section-content__documents {
  color: $c-primary;
  padding-left: rem(20);
}

.section-content__contact-us {
  margin-bottom: $bl * 2;
}

.sluzby--img {
  width: 100%;
  margin: 0 auto;
  max-width: rem(1730);
  margin-bottom: rem(112);

  img {
    width: 100%;
    height: auto;
  }
}
