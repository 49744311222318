// .site-inner {
//   overflow: auto;
// }

.img--responsive {
  max-width: 100%;
  float: left;
}

.img--why {
  width: rem(375);

  @include query-to(768) {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
}

.link--text {
  color: $c-gray;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.linkedin-logo {
  margin-left: $bl/2;
  position: relative;
  top: -2px;
}

.section {
  padding: rem(100) 0;

  @include query-to(1024) {
    padding: rem(75) 0;
  }

  @include query-to(768) {
    padding: rem(50) 0;
  }

  &--secondary {
    background: #fafafa;
  }

  &--services {
    .section-content__buttons {
      text-align: center;
      padding-bottom: $bl*2;
    }
  }

  &--cta {
    .section-content {
      width: 80%;

      @include query-to(1200) {
        text-align: center;
        width: 100%;
      }
    }

    .btn--link {
      margin-left: $bl;

      @include query-to(768) {
        margin-left: 0;
      }
    }
  }

  &--blog {
    .section-content__buttons {
      text-align: center;
      margin-top: $bl*2;
    }
  }

  &--contact {
    background: $c-secondary;
  }

  &-content {
    width: 65%;
    margin: 0 auto;
    font-family: $font-sans;

    @include query-to(768) {
      width: 100%;
    }

    &--fluid {
      width: inherit;
    }

    &__title {
      color: $c-black;

      .section--contact & {
        color: $c-white;
      }

      &--centered {
        text-align: center;
      }
    }

    &__main {
      font-size: rem(16);
      padding-bottom: $bl;

      &--centered {
        text-align: center;
      }

      &--sm {
        font-size: rem(14);
      }
    }

    &__buttons {
      margin: 0 auto;
    }
  }
}

.img--service {
  width: 200px;
}

.card-link {
  width: 100%;
  max-width: rem(300);
}

.card {
  text-align: center;
  cursor: pointer;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  padding-top: rem(70);

  &__item {
    padding: $bl;
    background-color: #fafafa;
    z-index: -1;
    -webkit-transition: 150ms linear all;
    transition: 150ms linear all;

    @include query-to(768) {
      // background-color: #fff;
      padding-top: 0;
    }
  }

  &-image {
    height: rem(170);
    margin-bottom: $bl;

    &--family-office {
      background: url("../img/service1.svg") 50% 50% no-repeat;
      background-size: 150px auto;
    }

    &--investicni-poradenstvi {
      background: url("../img/service2.svg") 50% 50% no-repeat;
      background-size: 164px 126px;
    }
  }

  &-content {
    &:before {
      display: block;
      content: "";
      height: $bl/2;
      border-top: 1px solid #eaeaea;
    }

    &__title {
      color: $c-quaternary;
      text-transform: uppercase;
      font-size: rem(18);
      font-weight: bold;
      letter-spacing: 0.1px;
    }

    &__description {
      color: #919191;
      font-size: rem(14);
    }
  }

  &:hover {
    .card__item {
      background: $c-primary;
      -webkit-box-shadow: 0 0 50px lighten($c-gray, 10%);
      box-shadow: 0 0 50px lighten($c-gray, 10%);
      z-index: 1;

      @include query-to(768) {
        background: #50e3c2;
        -webkit-box-shadow: none;
        box-shadow: none;
        z-index: -1;
        width: 100%;
      }
    }

    .card-image {
      @include query-from(768) {
        &--family-office {
          background: url("../img/service1_h.svg") 50% 50% no-repeat;
          background-size: 150px auto;
        }

        &--investicni-poradenstvi {
          background: url("../img/service2.svg") 50% 50% no-repeat;
          background-size: 164px 126px;
        }
      }
    }

    .card-content {
      &:before {
        border-top: 1px solid #4cd4b6;
      }

      &__title,
      &__description {
        color: $c-white;
      }
    }
  }
}

.quote {
  font-style: normal;
  font-family: $font-sans;

  &::before {
    display: block;
    content: "";
    height: $bl*3;
    width: rem(42);
    background: url("../img/quotation-mark.svg") no-repeat;
    background-size: 42px 42px;
  }

  .author-name {
    &::before {
      display: block;
      content: "";
      height: $bl;
    }

    font-weight: bold;
    font-size: rem(18);
    color: $c-black;
    display: block;
  }

  .author-position {
    font-size: rem(14);
  }
}

.blog-post__title {
  font-family: $font-sans;
  font-size: rem(18);
  line-height: rem(28);
  padding: $bl $bl/2 0;
}

.blog-post__image {
  -webkit-transition: 150ms linear all;
  transition: 150ms linear all;
}

.blog-post-link {
  position: relative;
  display: inline-block;

  &:hover {
    .img-overlay {
      opacity: 0.5;
    }

    .blog-post__image {
      -webkit-box-shadow: 0 0 50px lighten($c-gray, 10%);
      box-shadow: 0 0 50px lighten($c-gray, 10%);
    }
  }
}

.blog-post__perex {
  font-size: rem(14);
  padding: 0 $bl/2;
}

.list--content {
  font-weight: bold;
  margin-left: $bl/1.5;
}

.img--circle {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -o-border-radius: 50%;
}

.service-list {
  color: $c-primary;
  padding-left: 20px;
  li {
    margin-bottom: 10px;
  }
  p {
    color: $c-black;
  }
}

.para {
  font-family: $font-family-primary;
  margin-bottom: 28px;
}
