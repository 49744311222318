body {
  font-family: $font-sans;
  font-size: $base-size;
  font-weight: normal;
  line-height: $base-line;
  background-color: $base-bg-color;
  color: $base-color;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-serif;
  font-size: inherit;
  font-weight: bold;
  line-height: inherit;

  a {
    text-decoration: none;
  }
}

p, ul, ol, dl, table, blockquote, pre, figure {
  margin-bottom: 0;
}

ul ul, ol ol, ul ol, ol ul {
  margin-bottom: 0;
}

blockquote {
  font-style: italic;

  p {
    margin: 0;
  }
}

a {
   color: $c-link;
   text-decoration: none;

  &:hover {
    color: $c-link-hover;
  }
}

.table {
  display: table;
  width: 100%;
  height: 100%;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  height: 100%;
}
