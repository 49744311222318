.entry__content {

  color: $c-black;

  h1 {
    display: block;
    width: 100%;
    font-size: 36px;
    letter-spacing: 0.2px;
    text-align: left;
    color: $c-black;
    line-height: 46px;
    margin-bottom: $bl/2;
    margin-top: $bl;
  }

  h2 {
    display: block;
    width: 100%;
    font-size: 28px;
    letter-spacing: 0.2px;
    text-align: left;
    color: $c-black;
    line-height: 36px;
    margin-bottom: $bl/3;
    margin-top: $bl;
  }

  h3 {
    display: block;
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: left;
    color: $c-black;
    margin-bottom: $bl/4;;
    margin-top: $bl;
    clear: both;
  }

  p {
    clear: both;
  }

  img {

    margin: $bl/3 0;

    &.alignleft {
      float: left;
    }

    &.alignright {
      float: right;
    }

    &.alignnone {
      display: block;
      float: left;
    }
  }

  ul, ol {
    display: block;
    margin: $bl/2 0;

    li {
      margin-left: $bl;
      margin-bottom: $bl/3;
    }
  }

  blockquote {
      font-style: normal;
      font-family: $font-sans;
      margin: $bl*2 0;
      padding: 0 $bl;

      &::before {
        display: block;
        content: "";
        height: $bl*3;
        width: rem(42);
        background: url('../img/quotation-mark.svg') no-repeat;
        background-size: 42px 42px;
      }

      h1, h2, h3, p {
        margin: 0 !important;
      }

      p {
        font-size: rem(16);
        text-align: left;
      }
  }


}
