.widget__title {
  font-size: rem(18);
  font-weight: bold;
  line-height: 1.56;
  letter-spacing: 0.1px;
  text-align: center;
  text-transform: uppercase;
  color: $c-black;

  &::after {
    display: block;
    content: "";
    margin: $bl/2 auto;
    border-bottom: 1px solid $c-primary;
    width: rem(52);
    text-align: center
  }
}

.recent-posts__post {
  padding-bottom: $bl;
}

.widget_newsletter {
  border: 1px solid #d0f5ec;
  padding: $bl*1.5 $bl*1.5 $bl*0.5;
}

.sidebar {
  .widget {
    margin-bottom: $bl*2;

    .gform_wrapper .top_label input.medium {
      width: 100% !important;
    }

    .form__input {
      padding: 0;

      &--text {
        width: 100%;
        margin-bottom: $bl/2;
      }
    }

    &--pinned {
      @include query-from(800) {
        max-width: rem(360);
        position: fixed;
        top: rem(25);
      }

      &-bot {
        @include query-from(800) {
          max-width: rem(360);
          position: absolute;
        }
      }
    }
  }
}
