/*==========================================================================*\
  # Site Header
\*==========================================================================*/
.site-header {
  padding: $bl 0;
  background: $c-white;

  &--mobile {
    height: 100%;
  }
}

.site-title {
  position: relative;
  font-family: $font-sans;
  font-size: rem(28);
  font-weight: bold;
}

.title-area {
  &--primary {
    float: left;
  }
}

.logo {
  position: absolute;
  z-index: 2;
  width: rem(130);
  height: rem(56);
}
