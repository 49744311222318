/*==========================================================================*\
  # Grid
\*==========================================================================*/
.wrap {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 40px;
  @include clearfix();

  @include query-to(1024) {
    padding: 0 30px;
  }

  @include query-to(768) {
    padding: 0 20px;
  }

  &--full {
    max-width: 1600px;
  }

  &--fluid {
    padding: 0;
  }

  &--line {
    &::after {
      display: block;
      content: "";
      padding-bottom: rem(60);
      border-bottom: 1px solid #eaeaea;
    }
  }
}

.row {
  float: left;
  width: 100%;
}

.col {
  float: left;
  padding: 0 $bl;

  @include query-to(768) {
    padding: 0;
  }
}

.col--np {
  padding: 0;
}

.col--full {
  width: 100%;
}

.col--center {
  text-align: center;
}

.col--2-3 {
  width: 66.666666667%;

  @include query-to(1024) {
    width: 100%;
  }
}

.col--1-2 {
  width: 50%;

  @include query-to(1024) {
    width: 100%;
    margin-bottom: $bl;
  }

  @include query-to(768) {
    width: 100%;
    margin-bottom: $bl;
  }
}

.col--1-3 {
  width: 33.33333%;

  @include query-to(1024) {
    width: 50%;

    .section--why & {
      display: none;
    }
  }

  @include query-to(768) {
    width: 100%;
  }
}

.col--1-4 {
  width: 25%;
}

.col--1-5 {
  width: 20%;
}

.col--1-10 {
  width: 10%;
}

.site-content {
  width: 100%; // 65% when sidebar is present - set in blog.scss
}

.sidebar {
  float: right;
  width: 30%;

  @include query-to(800) {
    float: left;
    width: 100%;
    margin-top: $bl;
  }
}

.entry {
  margin: 0 auto;
  padding-bottom: $bl*2;

  @include query-to(768) {
    padding-bottom: $bl;
  }
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;

  &--center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &--hcenter {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &--vcenter {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  &--row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: inherit;
    flex-flow: inherit;

    @include query-to(1024) {
      .section--cta &,
      .section--service-one &,
      .section--service-two & {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column;
        flex-flow: column;
      }
    }

    @include query-to(768) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column;
      flex-flow: column;
    }
  }
}
