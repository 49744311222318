/*==========================================================================*\
  # Entry
\*==========================================================================*/

.entry-title {
}

.entry-featured-img {
  margin-bottom: $bl;
}

.entry {

  h1 {
    @extend .h1;
  }

  h2 {
    @extend .h2;
  }

  &__header .h2 {
    line-height: rem(56);
  }

  &-recap {
    font-size: rem(20);
    line-height: rem(32);
    font-style: italic;
    color: $c-black;

    &:after {
      content: '';
      display: block;
      width: 15%;
      border-bottom: 1px solid $c-black;
      padding-top: $bl;
      margin-bottom: $bl;
    }
  }

  p {
    margin-bottom: $bl;
  }

  ul {
    @extend .ul;
  }

  strong {
    color: $c-black;
  }

  blockquote {
    @extend .blockquoute;
  }


}

.category-link {
  margin-left: $bl/2.5;
}

.entry-meta__category {
  @include query-to(768) {
    display: none;
  }
}
