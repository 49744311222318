.swiper-slide .col--2-3 {
  margin-top: $bl*3;
}

.slider2-buttons {
  top: 0;
  left: 0;
  right: 0;
  z-index: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.slider-buttons {
  top: 0;
  left: 0;
  right: 0;
  z-index: 500;
  display: flex;
  justify-content: center;

  @include query-to(768) {
    left: $bl/2;
    flex-flow: row;
  }

  &__btn {
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    font-size: rem(26);
    margin-right: $bl/2;

    &--prev {
      background: url("../img/arrow-left.svg") 50% 50% no-repeat;
      background-size: 22px;

      &:hover {
        background: url("../img/arrow-left_h.svg") 50% 50% no-repeat;
        background-size: 22px;
      }
    }

    &--next {
      background: url("../img/arrow-right.svg") 50% 50% no-repeat;
      background-size: 22px;

      &:hover {
        background: url("../img/arrow-right_h.svg") 50% 50% no-repeat;
        background-size: 22px;
      }
    }
  }
}
