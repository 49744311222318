.hero {
  background-color: $c-secondary;
  @include at3x("../img/Header", "png");
  background-repeat: no-repeat;
  background-size: 38%;
  background-position: 80% bottom;
  padding: rem(130) 0 rem(55) 0;

  @include query-to(1800) {
    background-size: 70%;
    background-position: right bottom;
  }

  @include query-to(1024) {
    padding: rem(60) 0 rem(40);
  }

  @include query-to(600) {
    background-image: none;
  }

  &-content {
    margin-top: rem(68);
    color: $c-white;
    max-width: rem(600);

    &__title {
      padding-bottom: rem(16);
    }

    &__block {
      padding-bottom: rem(54);
      font-family: $font-sans;

      @include query-to(768) {
        br {
          display: none;
        }
      }
    }

    &__buttons {
      padding-bottom: rem(106);

      @include query-to(768) {
        padding-bottom: rem(60);
      }
    }
  }
}

.home {
  .menu-open__link {
    color: $c-white;
  }

  .menu {
    &-item {
      &.current-menu-item {
        .menu-item__link {
          @include query-from(1024) {
            color: $c-white;
          }
        }
      }
    }
  }

  .site-header {
    max-width: 100%;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

.section--why {
  .img {
    @include query-to(768) {
      display: none;
    }
  }
}

.citation {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.citation--img {
  width: 40%;
  padding-top: rem(22);

  @include query-to(720) {
    display: none;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.citation--img__kunz {
  width: 60%;
  padding-top: 0;
}

.citation-buttons {
  display: flex;
  position: absolute;
  bottom: rem(28);
  left: 50%;
  transform: translateX(-50%);
}

.citation-section {
  position: relative;
  padding-top: rem(42);
  @include query-to(720) {
    padding-bottom: rem(84);
  }
}

.mc-field-group {
  input {
    width: 100%;
    padding-left: 0 !important;
    border-bottom: solid rem(1) transparent;
    margin-bottom: rem(14);

    &:active,
    &:focus {
      border-bottom: solid rem(1) #50e3c2;
    }
  }

  label {
    left: 0 !important;
  }
  margin-bottom: rem(42);
}
