.btn {
  text-transform: uppercase;
  font-family: $font-sans;
  font-size: rem(14);
  font-weight: 800;
  text-decoration: none;
  padding: rem(7);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 100ms linear all;

  &--arrow {
    &::after {
      float: right;
      width: $bl;
      text-align: right;
      content: "\279C";
    }
  }

  &-wrap {
    display: inline-block;

    &--center {
      margin: 0 auto;
    }

    &--right {
      float: right;
    }
  }

  &--link {
    display: inline-block;
    padding: rem(7) 0;
    width: inherit;
    text-transform: none;
    font-size: rem(16);
    line-height: rem(28);
    color: $c-primary;

    &:hover {
      color: #8a8a8a;
      text-decoration: underline;
    }
  }

  &--primary {
    background: $c-primary;
    color: $c-white;
    line-height: rem(4);

    .btn__text {
      float: left;
      line-height: rem(42);
      padding: 0 rem(20);
    }

    &:hover {
      background: $c-tertiary;
      color: $c-white;

      .btn__arrow {
        background: $c-primary;
      }
    }
  }

  &--blog {
    background: $c-white;
    color: $c-primary;
    line-height: rem(4);
    border: 1px solid $c-primary;

    .btn__text {
      float: left;
      padding: 0 rem(20);
    }

    .btn__arrow {
      background: $c-tertiary;
      position: relative;
      width: rem(42);
      height: rem(42);
      display: flex;
      align-items: center;
      justify-content: center;

      &::after {
        color: $c-white;
        content: "\279C";
      }
    }

    &:hover {
      background: $c-tertiary;
      color: $c-white;

      .btn__arrow {
        background: $c-primary;
      }
    }

    &-next {
      .btn__arrow {
        &::after {
          color: $c-white;
          content: "\279C";
          transform: rotate(180deg);
        }
      }
    }
  }

  &--form-submit {
    float: right;
    margin: 0 $bl/2;

    .widget_newsletter & {
      float: inherit;
      margin: 0 auto;
    }
  }
}

.btn__arrow {
  background: $c-tertiary;
  position: relative;
  width: rem(42);
  height: rem(42);
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: "\279C";
  }
}

.button-sub {
  margin-right: rem(30);
  margin-left: rem(30);
}
