/*--------------------------------------------------------------------------*\
  # Media object
\*--------------------------------------------------------------------------*/
.media {
  @include clearfix();
  display: block;
}

.media__img {
  float: left;
}

.media__img--rev {
  float: right;
}

.media__img img, .media__img--rev img {
  display: block;
}

.media__body {
  overflow: hidden;
}

.media__body, .media__body > :last-child {
  margin-bottom: 0;
}

.media--vrt {

  .media__img {
    float: none;
    display: block;
  }
}

/*--------------------------------------------------------------------------*\
  # Flag object
\*--------------------------------------------------------------------------*/
.flag {
  display: table;
  // width: 100%;
}

.flag__img, .flag__body {
  display: table-cell;
  vertical-align: middle;

  .flag--top & {
    vertical-align: top;
  }

  .flag--bottom & {
    vertical-align: bottom;
  }
}

.flag__img {

  > img {
    display: block;
    max-width: none;
  }
}

.flag__body {
  // width: 100%;
}
