.gform_wrapper .top_label .gfield_label {
  color: #8a8a8a;
  font-weight: normal;
  font-size: rem(14);
}

.gform_wrapper .top_label .ginput_container {
  margin-top: 0 !important;
}

.gfield_required {
  display: none;
}

.gform_wrapper {
  input[type="text"],
  textarea {
    background: transparent !important;
    color: #fff;
    border-bottom: 1px solid #eaeaea;
    font-size: rem(14) !important;

    .sidebar & {
      color: #000;
    }

    &:focus,
    &:hover {
      border-bottom: 1px solid #50e3c2;
    }
  }

  .gfield_label {
    position: absolute;
    top: 6px;
    left: 31px;
    -webkit-transition: -webkit-transform 200ms ease !important;
    transition: -webkit-transform 200ms ease !important;
    transition: transform 200ms ease !important;
    transition: transform 200ms ease, -webkit-transform 200ms ease !important;
    pointer-events: none !important;

    @include query-to(641) {
      top: 10px;
    }
  }
}

.form__input {
  padding: 0 $bl;
  position: relative;

  &--text {
    width: 33.33333%;
    display: inline-block;
    margin-bottom: $bl*2;

    @include query-to(768) {
      width: 100%;
    }
  }
}

.gfield_label.freeze {
  -webkit-transform: translateY(-28px);
  transform: translateY(-28px);
}

.validation_error {
  border-top: none !important;
  border-bottom: none !important;
}

.validation_error,
.gfield_description {
  color: #f0d800 !important;
}

.gfield_error .gfield_label {
  color: $c-gray !important;
}

.gfield_error input,
.gfield_error textarea {
  border: none !important;
  border-bottom: 1px solid #eaeaea !important;
  width: 100% !important;
}

.gfield.gfield_error:not(.form__input--textbox) {
  width: 33.33333% !important;
}

.gfield_error {
  background: none !important;
  border: none !important;
}
