#map {
  height: 420px;
}

.section--map {
  padding: 0;
}

.contact-office {
  font-size: rem(24);
  line-height: rem(42);
  color: $c-black;
}

.person {
  color: $c-black;
  margin-right: 52px;
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 49%;

  &__name {
    font-weight: bold;
    font-size: rem(18);
    padding-bottom: $bl/3;
  }

  &__details {
    text-align: center;
    .phone,
    .email {
      display: block;
    }

    padding-bottom: $bl/3;
  }

  &__image {
    padding-bottom: $bl;
    max-width: rem(300);
    display: flex;
    justify-content: center;
  }

  @include query-to(660) {
    margin-right: 0;
  }
}

.persons {
  max-width: rem(730);
  margin: 0 auto;
  margin-bottom: rem(56);
}

.person-box {
  display: flex;
  @include query-to(660) {
    flex-flow: column;
  }
}

.person-text {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  width: 49%;
}

.person-quote {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 28px;
  font-weight: 700;
  color: $c-black;
}

.contact-links {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column wrap;
  margin-bottom: 56px;
}
