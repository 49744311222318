/*==========================================================================*\
  # Config
\*==========================================================================*/

// Color palette
$c-white: #fff;
$c-black: #121419;
$c-gray: #8a8a8a;
$c-primary: #50E3C2;
$c-secondary: #292948;
$c-tertiary: #4cd4b6;
$c-quaternary: #282833;

// Default colors
$c-base: $c-gray;
$c-link: $c-black;
$c-link-hover: $c-primary;

// Defaults
$font-family-primary: "proxima-nova", "Helvetica Neue", "Helvetica Neue LT Pro", Helvetica, Arial, sans-serif;
$font-family-secondary: "Helvetica Neue", "Helvetica Neue LT Pro", Arial, sans-serif;
$base-size: rem(16);
$base-line: rem(28);
$base-color: $c-base;
$base-bg-color: $c-white;

// Alias
$bl: $base-line;
$font-sans: $font-family-primary;
$font-serif: $font-family-secondary;
