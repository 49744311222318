/*==========================================================================*\
  # Navigation
\*==========================================================================*/
.nav {
  &--primary {
    float: right;
    margin-top: rem(14);

    &-open {
      @include query-to(768) {
        z-index: 1;
        width: 100%;
        float: left;
        height: 100%;
        background: $c-secondary 2.8rem 2.8rem no-repeat;
        text-align: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin-top: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        .menu-open__link {
          color: $c-white;
        }
      }
    }
  }

  &--secondary {
  }
}

.menu {
  font-family: $font-sans;
  font-weight: bold;
  font-size: rem(14);
  list-style: none;
  text-transform: uppercase;

  &-open {
    display: none;
    @include query-to(768) {
      display: inline;
      position: absolute;
      right: 0;
      top: $bl*1.5;

      &__link {
        color: $c-secondary;
        padding: $bl;
        font-size: rem(16);
      }
    }
  }

  @include query-to(768) {
    width: 100%;
  }

  &-item {
    display: inline-block;
    margin-left: $bl*1.5;

    @include query-to(768) {
      display: none;
      width: 100%;
      margin-left: 0;

      &--block {
        display: block;
      }
    }

    &__link {
      text-decoration: none;
      color: #8a8a8a;

      @include query-to(768) {
        color: $c-white;
        display: block;
        width: 100%;
        padding: $bl/2;
        font-size: rem(22);
      }
    }

    &.current-menu-item {
      .menu-item__link {
        color: $c-secondary;

        @include query-to(768) {
          color: $c-primary;
        }
      }
    }
  }
}

.menu--submenu {
  margin: 0 auto 2px;

  .blog &,
  .single &,
  .archive &,
  .category & {
    margin: 0 auto $bl*2;
  }

  text-align: center;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;

  @include query-to(768) {
    display: none;
  }

  .item {
    list-style-type: none;
    display: inline-block;
    padding: 0 $bl;

    &__link {
      display: inline-block;
      padding: $bl 0 rem(26) 0;

      &::after {
        display: block;
        position: relative;
        top: 28px;
        border-bottom: 2px solid transparent;
        content: "";
        z-index: 1;
      }

      &:hover {
        color: $c-black;

        &::after {
          border-bottom: 2px solid #50e3c2;
        }
      }
    }
  }
}

.active {
  &::after {
    border-bottom: 2px solid #50e3c2 !important;
  }
}

.breadcrumbs-area {
  padding: $bl/2 0;
}
