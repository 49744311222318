/* This stylesheet generated by Transfonter (https://transfonter.org) on June 22, 2017 8:39 AM */

@font-face {
	font-family: 'Helvetica Neue LT Pro';
	src: url('../fonts/HelveticaNeueLTPro-Th.eot');
	src: url('../fonts/HelveticaNeueLTPro-Th.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HelveticaNeueLTPro-Th.woff') format('woff'),
		url('../fonts/HelveticaNeueLTPro-Th.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue LT Pro';
	src: url('../fonts/HelveticaNeueLTPro-Lt.eot');
	src: url('../fonts/HelveticaNeueLTPro-Lt.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HelveticaNeueLTPro-Lt.woff') format('woff'),
		url('../fonts/HelveticaNeueLTPro-Lt.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica Neue LT Pro';
	src: url('../fonts/HelveticaNeueLTPro-Bd.eot');
	src: url('../fonts/HelveticaNeueLTPro-Bd.eot?#iefix') format('embedded-opentype'),
		url('../fonts/HelveticaNeueLTPro-Bd.woff') format('woff'),
		url('../fonts/HelveticaNeueLTPro-Bd.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}
