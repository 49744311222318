.blog, .archive, .category, .single {
  // We need sidebar shown on these pages
  .site-content {
    float: left;
    width: 60%;

    @include query-to(800) {
      width: 100%;
    }
  }
}

.blog {
  .sticky-stop {
    margin-bottom: $bl*2;
  }
}

.img--post-thumbnail {
  -webkit-transition: 150ms linear all;
  transition: 150ms linear all;

  &::before {
    position: absolute;
    background: $c-primary;
    opacity: 0.75;
    content: "";
  }
}

.img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  -webkit-transition: .2s ease;
  transition: .2s ease;
  background-color: $c-primary;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

  &::after {
    content: "\279C";
    color: $c-white;
    font-size: rem(22);
  }
}

.img--post-author-avatar {
  width: 56px;
  height: 56px;
  float: left;
  margin-right: $bl/1.5;
}

.img--post-thumb-small {
  margin-right: $bl/2;
}

.article-link {
  float: left;
  position: relative;
  margin-bottom: $bl;

  &:hover {
    .img-overlay {
      opacity: 0.5;
    }
    .blog & .img--post-thumbnail {
      -webkit-box-shadow: 0 0 50px lighten($c-gray, 10%);
              box-shadow: 0 0 50px lighten($c-gray, 10%);
    }
  }
}

.recent-posts__post {
  .img {
    @include query-to(768) {
      display: none;
    }
  }

  -webkit-box-orient: horizontal;

  -webkit-box-direction: normal;

      -ms-flex-flow: row;

          flex-flow: row;
  list-style-type: square;
}

.blog-post {

  @include query-to(1024) {
    text-align: center;
  }

  @include query-to(768) {
    padding-bottom: $bl;
  }
}
