.jm {
  text-align: right;
  font-size: rem(14);

  @include query-to(768) {
    text-align: center;
  }
}

.site-footer {
  padding: rem(70) 0;
}

.site-title--footer {
  display: inline-block;
  width: rem(130);
  height: rem(56);
}
.site-title__link {
  display: inline-block;
  width: rem(130);
  height: rem(56);
}

.copyright {
  font-size: rem(14);
  @include query-to(650) {
    margin-bottom: rem(28);
  }
}

.footer-logo {
  display: flex;
  justify-content: center;
  margin-bottom: rem(70);
}

.footer-copyright {
  display: flex;
  justify-content: space-between;

  @include query-to(650) {
    flex-flow: column;
    align-items: center;
  }
}

.footer-link {
  color: $c-primary;
  &:hover {
    text-decoration: underline;
  }
}
