/*==========================================================================*\
  # Functions
\*==========================================================================*/
@mixin box-sizing($type) {
  box-sizing: $type;
}

@mixin clearfix() {
  *zoom: 1;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@function rem($size) {
  @return ($size / 10) * 1rem;
}

$q-unit: 1px;
@mixin query-to($media) {
  @media (max-width: $media * $q-unit) {
    @content;
  }
}

@mixin query-from($media) {
  @media (min-width: $media * $q-unit) {
    @content;
  }
}

@mixin at3x($path, $ext: "jpg") {
  $at1x_path: "#{$path}.#{$ext}";
  $at2x_path: "#{$path}@2x.#{$ext}";
  $at3x_path: "#{$path}@3x.#{$ext}";

  background-image: url("#{$at1x_path}");

  @media only screen and (-o-min-device-pixel-ratio: 5/4),
    only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (min-device-pixel-ratio: 1.25),
    only screen and (min-resolution: 1.25dppx) {
    background-image: url("#{$at2x_path}");
  }

  @media only screen and (-o-min-device-pixel-ratio: 9/4),
    only screen and (-webkit-min-device-pixel-ratio: 2.25),
    only screen and (min-device-pixel-ratio: 2.25),
    only screen and (min-resolution: 2.25dppx) {
    background-image: url("#{$at3x_path}");
  }
}
